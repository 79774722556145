<template>
  <div class="div_home">
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <div class="head_input ">
            <div class="div_input">
              <label class="div_input_text">审核状态:</label>
              <el-select	 v-model="queryInfo.submissionStatus"	clearable   placeholder="全部">
                <el-option key="0" label="开发中" value="INIT"> </el-option>
                <el-option key="1" label="审核中" value="AUDITING"> </el-option>
                <el-option key="2" label="审核驳回" value="AUDIT_REJECT"> </el-option>
                <el-option key="3" label="待上架" value="WAIT_RELEASE"> </el-option>
                <el-option key="4" label="准入不可营销" value="BASE_AUDIT_PASS"> </el-option>
                <el-option key="5" label="灰度中" value="GRAY"> </el-option>
                <el-option key="6" label="已上架" value="RELEASE"> </el-option>
                <el-option key="7" label="已下架" value="OFFLINE"> </el-option>
                <el-option key="8" label="被强制下架" value="AUDIT_OFFLINE"> </el-option>
              </el-select>
            </div>
            <div class="div_input">
              <label class="div_input_text">名称:</label>
              <el-input  placeholder="请输入小程序名称"  v-model="queryInfo.name"  clearable  @clear="search"></el-input>
            </div>
            <div class="div_input">
              <label class="div_input_text">模板appId:</label>
              <el-input  placeholder="请输入模板appId"  v-model="queryInfo.appId"  clearable  @clear="search"></el-input>
            </div>
            <div class="div_input">
              <label class="div_input_text">状态:</label>
              <el-select  v-model="queryInfo.status"  clearable  placeholder="全部">
                <el-option key="-1" label="全部" value=""> </el-option>
                <el-option key="input_status_1" label="开启" value="1"></el-option>
                <el-option key="input_status_0" label="关闭" value="0"></el-option>
              </el-select>
            </div>
            <el-button  class="headButtonSingle"  type="primary"  @click="search()"  icon="el-icon-search">搜索</el-button>
            <el-button  class="headButtonSingle"  type="primary"  @click="clearSearch()"  icon="el-icon-refresh">重置</el-button>
            <el-button  class="headButtonSingle"  type="primary"  @click="toAdd()"  icon="el-icon-plus">新增</el-button>
            <el-button  class="headButtonSingle"  type="primary"  @click="toResubmitForReview()"  icon="el-icon-upload2">
              驳回重新提交
            </el-button>
            <!-- 按钮切换  未备案/已备案 -->
            <el-button  class="headButtonSingle"  type="primary"  @click="toBan()" style="font-size: 15px">{{ register }}</el-button>
            <el-button  class="headButtonSingle"  type="warning" plain  @click="stickup()" size="small" style="font-size: 15px">复制/粘贴</el-button>
<!--            Yang 多选，批量粘贴-->
            <div class="block" style="margin-left: 5px" >
              <el-cascader
                  :options="tbThirdPartySmallRoutineType"
                  :props="props"
                  size="medium"
                  collapse-tags
                  clearable
                  @change="change"></el-cascader>
            </div>
            <el-button  class="headButtonSingle"  type="success"  @click="serialRestore()" size="small" style="font-size: 15px; margin-left: 5px">还原</el-button>
            <el-button  class="headButtonSingle"  type="success" @click="dialogVisible = true" size="small" style="font-size: 15px; margin-left: 5px">应用配置</el-button>
            <el-dialog
                title="应用配置"
                :visible.sync="dialogVisible"
                width="50%">
              <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
              <div style="margin: 15px 0;"></div>
              <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                <el-checkbox v-for="city in sort_data_list" :label="city" :key="city.id" style="margin-left: 20px; margin-top: 10px; width: 150px">
                  {{city.name}}</el-checkbox>
              </el-checkbox-group>
              <el-divider content-position="left">结果显示，未通过数量：{{ messages.length }}</el-divider>
              <el-table
                  :data="messages"
                  style="width: 100%">
                <el-table-column  label="结果" width="120">
                  <template slot-scope="scope">
                    <el-tag type="danger" v-if="!scope.row.isCompletedInstructions">失败</el-tag>
                  </template>
                </el-table-column>

                <el-table-column
                    prop="name"
                    label="名字"
                    width="300">
                </el-table-column>
                <el-table-column
                    prop="operateMessage"
                    label="描述"
                    width="300">
                </el-table-column>
              </el-table>
              <span slot="footer" class="dialog-footer">
                <el-button @click="messages = []">清 空</el-button>
                <el-button @click="dialogVisible = false">关 闭</el-button>
                <el-button type="primary" @click="useDeploy()" :loading="useDeployLoading">{{ useDeployLoading? '加载中' : '执 行' }}</el-button>
              </span>
            </el-dialog>

          </div>
        </el-row>
		    <el-row>
          <batch_navigator	ref="batch_navigator"	
              v-if="queryInfo.appletType!=''"	:appletType="queryInfo.appletType"
              :select_small_routines="batch_class.select_small_routines"	
              :is_show_yumma_status.sync="is_show_yumma_status" 
              :icp_status_name_map_value="icp_status.name_map_value"
              @fresh_data = "()=>{getList();}"
              @choose_app_content="(e)=>{queryInfo.appContent=e;queryInfo.pageNum=1;getList();}"		
              @choose_batch_class="(e)=>{queryInfo.batchClassId=e;queryInfo.pageNum=1;getList();}"
              @submit_batch_classes="(e)=>{sort_data_list=e;}"
              @order_by_today_uv= "queryInfo.orderBy ='today_uv' ;getList(); "
              @order_by_yesterday_uv ="queryInfo.orderBy ='yesterday_uv' ;getList();"
              @order_by_time="queryInfo.orderBy ='time';getList();"
              @refresh_list_keyword = "refresh_list_keyword"
              :register="register"
              >
          </batch_navigator>
        </el-row>
      </div>
      <!-- 列表 border:边框  stripe隔行变色-->
      <el-table :data="pageMes.list" border stripe	ref="table"	@selection-change="(e)=>{batch_class.select_small_routines = e;}">
		    <el-table-column	type="selection"	width="40"></el-table-column>
        <el-table-column label="模板名称" prop="name" fixed width="200">
          <template slot-scope="scope">
            <template v-if="scope.row.isTemplate"><div class="div_red_color">{{ scope.row.name }}</div></template>
            <template v-else>{{ scope.row.name }}</template>
          </template>
        </el-table-column>
        <el-table-column	label="appId"	prop="appId"	width="160"></el-table-column>
        <el-table-column label="审核状态" width="100">
          <template slot-scope="scope">
            <div v-if="scope.row.submissionStatus == 'INIT'">开发中</div>
            <div v-else-if="scope.row.submissionStatus == 'AUDITING'">审核中</div>
            <div v-else-if="scope.row.submissionStatus == 'AUDIT_REJECT'">审核驳回</div>
            <div v-else-if="scope.row.submissionStatus == 'WAIT_RELEASE'">待上架</div>
            <div v-else-if="scope.row.submissionStatus == 'BASE_AUDIT_PASS'">准入不可营销</div>
            <div v-else-if="scope.row.submissionStatus == 'GRAY'">灰度中</div>
            <div v-else-if="scope.row.submissionStatus == 'RELEASE'">已上架</div>
            <div v-else-if="scope.row.submissionStatus == 'OFFLINE'">已下架</div>
            <div v-else-if="scope.row.submissionStatus == 'AUDIT_OFFLINE'">被强制下架</div>
            <div v-else>其他:{{ scope.row.submissionStatus }}</div>
          </template>
        </el-table-column>
        <el-table-column  label="版本号"  prop="smallRoutineVersion"  width="70"></el-table-column>
        <el-table-column label="备注" prop="remark" width="120">
          <template slot-scope="scope" class="div_line_2">{{ scope.row.remark }}</template>
        </el-table-column>
        <el-table-column  label="商家id"  prop="userId"  width="160"></el-table-column>
        <el-table-column  label="第三方应用名称"  prop="applicationName"  width="200"></el-table-column>
        <el-table-column label="平台类别" width="100">
          <template slot-scope="scope">
            <div v-if="scope.row.flatform == 'alipay'">支付宝</div>
            <div v-else-if="scope.row.flatform == 'huawei'">华为</div>
            <div v-else-if="scope.row.flatform == 'douyin'">抖音</div>
          </template>
        </el-table-column>
        <el-table-column  label="模板名称"  prop="templateName"  width="200"></el-table-column>
        <el-table-column label="模板类型" prop="appletType" width="160">
          <template slot-scope="scope">
            <template v-if="scope.row.appletType == '1'"> 高德小程序 </template>
            <template v-else-if="scope.row.appletType == '2'">互动小程序</template>
            <template v-else-if="scope.row.appletType == '3'">高德临时1</template>
            <template v-else-if="scope.row.appletType == '4'">高德临时2</template>
            <template v-else-if="scope.row.appletType == '5'">高德临时3</template>
            <template v-else-if="scope.row.appletType == '6'"> 租机 </template>
            <template v-else> 未定义 </template>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remark" width="200">
          <template class="div_line_2" slot-scope="scope">{{ scope.row.remark }}</template>
        </el-table-column>
        <el-table-column  label="服务提报通过数"  prop="agreeService"  width="200"></el-table-column>
        <el-table-column  label="授权token"  prop="appAuthToken"  width="400"></el-table-column>
        <el-table-column	label="创建时间"	prop="gmtCreate"	width="300"></el-table-column>
        <el-table-column	label="更新时间"	prop="gmtModified"		width="300"></el-table-column>
        <el-table-column label="今日uv" prop="todayUv" fixed="right" width="80"	style="display: flex;">
          <template slot-scope="scope" >
            <label	@click="show_detail_uv_dialog(scope.row.id)"		style="cursor: pointer;flex: 1;">{{scope.row.todayUv}}</label>
          </template>
        </el-table-column>
        <el-table-column label="昨日uv" prop="yesterdayUv" fixed="right" width="80"></el-table-column>
        <el-table-column label="选择类别" prop="batchClassId" fixed="right" width="140" >
          <template slot-scope="scope">
            <el-select		:value = "scope.row.batchClassId"
                @change=" change_small_routine_prop(scope.row,'batchClassId',$event) ">
              <el-option		v-for="(item,index) in sort_data_list"	:key="index"
                  :value="parseInt(item.id)"	:label="item.name"	></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="选择小程序内容" prop="icpStatus" fixed="right" width="140" >
          <template slot-scope="scope">
                <el-select		:value = "scope.row.icpStatus"
                    @change=" change_small_routine_prop(scope.row,'icpStatus',$event) ">
                  <el-option		v-for="(item,index) in icp_status.name_map_value"	:key="index"
                      :value="index"	:label="item">
                  </el-option>
                </el-select>
          </template>
        </el-table-column>

        <el-table-column label="选择组编号" prop="serial" fixed="right" width="140" >
          <template slot-scope="scope">
            <el-select		:value = "scope.row.serial"
                          @change=" change_small_routine_serial(scope.row, $event) " filterable>
              <el-option	v-for="(item,index) in sort_data_list"	:key="index"
                            :value="item.serial"	:label="item.serial">
              </el-option>
            </el-select>
          </template>
        </el-table-column>

        <el-table-column label="是否直跳" prop="jumpToH5Status" fixed="right" width="80">
          <template slot-scope="scope">
            <el-switch		v-model="scope.row.jumpToH5Status"	@change="configStateChange(scope.row,'jumpToH5Status')"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="首次跳转" prop="isPreJumpToH5" fixed="right" width="80">
          <template slot-scope="scope">
            <el-switch		v-model="scope.row.isPreJumpToH5"	@change="configStateChange(scope.row,'isPreJumpToH5')"></el-switch>
          </template>
        </el-table-column>
        
        <el-table-column label="云码" prop="isShowYunma" fixed="right" width="65" v-if="is_show_yumma_status">
          <template slot-scope="scope">
            <el-switch		v-model="scope.row.isShowYunma"	@change="configStateChange(scope.row,'isShowYunma')"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="云码入会" prop="isShowYunmaMembership" fixed="right" width="80" v-if="is_show_yumma_status">
          <template slot-scope="scope">
            <el-switch		v-model="scope.row.isShowYunmaMembership"	@change="configStateChange(scope.row,'isShowYunmaMembership')"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="云码聚合" prop="isShowYunmaAcquisition" fixed="right" width="80" v-if="is_show_yumma_status">
          <template slot-scope="scope">
            <el-switch		v-model="scope.row.isShowYunmaAcquisition"	@change="configStateChange(scope.row,'isShowYunmaAcquisition')"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="淘宝客" prop="isShowTaobao" fixed="right" width="65" v-if="is_show_yumma_status">
          <template slot-scope="scope">
            <el-switch		v-model="scope.row.isShowTaobao"	@change="configStateChange(scope.row,'isShowTaobao')"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="灯火" prop="lightAdStatus" fixed="right" width="65" v-if="is_show_yumma_status">
          <template slot-scope="scope">
            <el-switch		v-model="scope.row.lightAdStatus"	@change="configStateChange(scope.row,'lightAdStatus')"></el-switch>
          </template>
        </el-table-column>
        <template v-if="!is_show_yumma_status">
          <el-table-column  v-for="item in keyword.colu"
            :label="item.label" :prop="item.prop" :key="item.prop"
            fixed="right" width="65" 
            >
            <template slot-scope="scope">
              <div class="keyword_button">
                {{ scope.row[item.prop] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column    fixed="right" width="110" label="刷新与详细">
            <template slot-scope="scope">
              <div class="keyword_button"  >
                <el-tooltip  effect="dark"  content="刷新"  placement="top-start"  :enterable="false">
                  <el-button  type="primary"  icon="el-icon-refresh"  size="mini" style="margin-left: 45px;"
                    @click="refresh_keyword(scope.row.appId)">
                  </el-button>
                </el-tooltip>
                <el-tooltip  effect="dark"  content="详情"  placement="top-start"  :enterable="false">
                  <el-button  type="primary"  icon="el-icon-s-data"  size="mini"
                    @click="show_keyword_dialog(scope.row)">
                  </el-button>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </template>

        <el-table-column label="状态" prop="status" fixed="right" width="65">
          <template slot-scope="scope">
            <el-switch		v-model="scope.row.status"	@change="userStateChange(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="配置" width="180" fixed="right">
          <template slot-scope="scope">
            <el-tooltip  effect="dark"  content="内容管理"  placement="top-start"  :enterable="false">
              <el-button  type="warning"  icon="el-icon-table-lamp"  size="mini"  @click="showAppletMessage(scope.row)"></el-button>
            </el-tooltip>
            <!-- 审核管理 -->
            <el-tooltip  v-if="!scope.row.isTemplate"  effect="dark"  content="审核管理"  placement="top-start"  :enterable="false">
              <el-button  type="warning"  icon="el-icon-setting"  size="mini"  @click="showExamine(scope.row)"></el-button>
            </el-tooltip>
            <!-- 服务商管理 -->
            <el-tooltip  effect="dark"  content="服务商管理"  placement="top-start"  :enterable="false">
              <el-button  type="warning"  icon="el-icon-s-help"  size="mini"  @click="showServiceProvider(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="120">
          <template slot-scope="scope">
            <el-tooltip
              v-if="!scope.row.isTemplate"
              effect="dark"
              content="编辑"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="showUpdate(scope.row.id)"
              ></el-button>
            </el-tooltip>
            <!-- 删除 -->
            <el-tooltip
              v-if="!scope.row.isTemplate"
              effect="dark"
              content="删除"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="deleteThis(scope.row.id)"
              ></el-button>
            </el-tooltip>
            <!-- 复制小程序排版 -->
            <el-tooltip
              v-if="scope.row.appletType == '1'"
              effect="dark"
              content="复制小程序排版"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="success"
                icon="el-icon-document-copy"
                size="mini"
                @click="showLayoutCoty(scope.row.id)"
              >
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- element分页组件 size-change每页最大变化数 current-change当前最大变化 layout功能组件-->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageMes.total"
        >
        </el-pagination>
      </div>
    </el-card>
    <el-dialog title="新增" :visible.sync="addMesVisible" width="50%">
      <add
        :refreshData="getList"
        :applications="applications"
        :templateList="templates"
				:appletType="queryInfo.appletType"
      />
    </el-dialog>
    <el-dialog title="编辑" :visible.sync="updateMes.visible" width="50%">
      <update
        v-if="updateMes.visible"
        :refreshData="getList"
        :updateId="updateMes.id"
        :visible="updateMes.visible"
        :applications="applications"
        :templateList="templates"
      />
    </el-dialog>
    <!-- 复制布局 -->
    <el-dialog
      title="复制布局"
      :visible.sync="layoutCopyShow.visible"
      width="50%"
    >
      <layoutCopy :showSingleId="layoutCopyShow.id" />
    </el-dialog>
    <!-- uv的弹窗 -->
    <uv_dialog	v-if="uv.is_show_dialog"	:small_routine_id="uv.small_routine_id"	
        @close="()=>{uv.is_show_dialog = false;}">
    </uv_dialog>	
    <!-- 弹窗 -->
    <el-dialog  :title="keyword.title"  v-if="keyword.is_show_dialog" :visible.sync="keyword.is_show_dialog" width="1500px">
      <keywordDialog  :appId="keyword.appId"  :keywordList="keyword.keywordList"
        @refresh_keyword="refresh_keyword"></keywordDialog>
    </el-dialog>
  </div>
</template>

<script>
import layoutCopy from "./applet1/childrenPage/layoutCopy.vue";
import { getNameAndIdListApplications } from "@/services/thirdParty/getNameAndIdListApplications";
import { getNameAndIdListTemplate } from "@/services/thirdParty/getNameAndIdListTemplate";
import { resubmitForReview } from "@/services/thirdParty/thirdParty2";
import add from "./add";
import update from "./update.vue";
import batch_navigator from './listComponentWang/batch_navigator.vue'
import uv_dialog from './listComponentWang/uv_dialog.vue'
import keywordDialog from './listComponentWang/keywordDialog'

import store from '@/store/index.js'
import axios from "axios";
import {mapState} from 'vuex';
import * as utility from '@/services/wCommonFunction.js';


export default {
  components: {
    add,
    update,
    layoutCopy,
    batch_navigator,
    uv_dialog,
    keywordDialog,
  },
  props: ["showMessage", "showExamine", "showServiceProvider","showSingleMes"],
  data() {
    return {
      // 搜索内容
      queryInfo: {
        //查询信息
        name: "",
        appId: "",
        status: "",
        isThirdParty: store.state.isThirdParty,
		    appletType:'',
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
        batchClassId:"",
        orderBy:"time",
        icpStatus: "", //小程序备案状态
      },
      // 分页信息
      pageMes: {
        list: [],
        total: 0,
        current: -1,
        pages: -1,
      },
      // 新增
      addMesVisible: false,
      // 编辑
      updateMes: {
        visible: false,
        id: "",
      },
      // 第三方应用列表
      applications: [],
      // 第三方应用列表
      templates: [],
      // 复制布局
      layoutCopyShow: {
        visible: false,
        id: "",
      },
      //批处理
      batch_class:{
        class_id_map_name:{},//id号对应的名字
        choose_id:-1,	//选择的id号
        is_show_batch_class_column:true,	//是否展示小程序的分类，和选择小程序的分类d
        select_small_routines:[],
      },
      //这里是小程序的实际内容
      app_content:{
        name_map_value:{
          "redpacket":"红包",
          "accountBook":"记账本",
          "lotteryEgg":"扭蛋机",
        }
      },
      // icp备案状态
      icp_status:{
        name_map_value:{
          "NOT_REGISTER":"未备案",
          // "PLATFORM_REJECT":"平台审核驳回",
          // "PLATFORM_AUDITING":"平台审核中(放行)",
          // "MIIT_AUDITING":"监管审核中(放行)",
          // "MIIT_REJECT":"监管审核驳回",
          "REGISTER_SUCCESS":"已备案",
        }
      },
      // 这里是展示uv的东西，也就是小程序的流量
      uv:{
        is_show_dialog:false,
        small_routine_id:"",
      },
      is_show_yumma_status:true,
      // 搜索关键词
      keyword:{
        is_show_dialog:false,
        keywordList:[],
        appId:null,
        title:null,
        colu:[
          {prop:'remainderNum',label:'剩余',},
          {prop:'rejectNum',label:'驳回',},
          {prop:'auditingNum',label:'审核中',},
          {prop:'passNum',label:'通过',},
          {prop:'quotaNum',label:'总数量',},
        ]
      },
      register: "未备案", //定义 未备案/已备案，默认未备案
      password: "", //密码
      tbThirdPartySmallRoutineType:[], //获取所有第三方（左边栏）
      //多选，批量粘贴
      props: {
        multiple: true,
        value:'id',
        label:'name',
        children: 'no' //这是二级选项，目前不需要，需要时改为 children: 'children'
      },
      UpdateStickupssList:[{}],
      //批处理的所有类别
      sort_data_list:[],
      //应用配置
      dialogVisible : false,
      checkAll: false,
      checkedCities: [],
      messages:[],
      useDeployLoading: false
    }
  },
  watch: {
    "$route.query.type"() {
      this.initAppletType();
    },
    //这里监控右边列表展示的内容，如果为搜索关键词，那么就把数据带上
    "is_show_yumma_status":  async function(newV,oldV){
      if(newV == false){
        this.refresh_keyword_table_data();
      }
    }
  },
  created() {
    this.init();
  },
  methods: {
    // 初始化
    init() {
      // 获取第三方应用列表
      this.getApplications();
      this.getTemplates();
      this.initAppletType();
      this.getAllType();
      // this.getBathClassSerial();
    },
    //点击搜索
    search() {
		this.queryInfo.pageNum = 1;
		this.getList();
    },
    // 重置搜索内容
    clearSearch() {
      this.queryInfo.name ="";
	  this.queryInfo.appId = "";
	  this.queryInfo.status = "";
	  this.queryInfo.submissionStatus ="";
	  this.getList();
    },
    // 设置小程序类型
    initAppletType() {
      let appletType = this.$route.query.type;
      this.$set(this.queryInfo, "appletType", appletType);
      this.getList();
    },
    // 获取列表
    async getList() {
      this.queryInfo.icpStatus = this.register // （已备案/未备案的按钮部分）关了可以看到其他状态（如果有）
      const { data: res } = await this.$http.post(
        "/thirdPartySmallRoutine/list",
        this.queryInfo
      );
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      // 列表内容
      this.pageMes.list = res.message.records;
      // 总数
      this.pageMes.total = res.message.total;
      setTimeout(this.refresh_keyword_table_data,100);
    },
    // 新增
    toAdd() {
      this.addMesVisible = true;
    },
    // 驳回重新提交
    async toResubmitForReview() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const res = await resubmitForReview();
      loading.close();
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.$message.success("操作成功");
      this.getList(); //刷新表格
    },
    // 最大数发生变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getList();
    },
    // 换页
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getList();
    },
    // 编辑
    showUpdate(id) {
      this.$set(this.updateMes, "id", id);
      this.$set(this.updateMes, "visible", true);
    },
    // 删除
    async deleteThis(id) {
      const result = await this.$confirm("此操作将永久删,是否继续?", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result != "confirm") {
        //取消删除
        return ;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.delete(
        "thirdPartySmallRoutine/del?id=" + id
      );
      loading.close();
      if ("200" != res.code) {
        return this.$message.error("删除失败:" + res.message);
      }
      this.$message.success("删除成功");
      this.getList(); //刷新表格
    },
    // 修改小程序状态
    async userStateChange(e) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.put(
        `/thirdPartySmallRoutine/updateStatus`,
        {
          id: e.id,
          status: e.status,
        }
      );
      loading.close();
      if (res.code != "200") {
        e.status = !e.status;
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
    },
    // 打开详情
    showAppletMessage(e) {
      this.showMessage(e);
    },
    isNull(e) {
      return e == null || e == undefined || e == "";
    },
    // 获取第三方应用列表
    async getApplications() {
      let res = await getNameAndIdListApplications();
      if (!res.success) {
        return;
      }
      this.applications = res.data;
    },
    // 获取第三方模板列表
    async getTemplates() {
      let res = await getNameAndIdListTemplate();
      if (!res.success) {
        return;
      }
      this.templates = res.data;
    },
    // 打开复制页
    showLayoutCoty(e) {
      this.$set(this.layoutCopyShow, "id", e);
      this.$set(this.layoutCopyShow, "visible", true);
    },
	
    //更改配置,这里更改的是配置表
    async	configStateChange(row,key){	//key就是isShowYunma这种
        const loading = this.$loading({lock: true,text: "Loading",spinner: "el-icon-loading",background: "rgba(0, 0, 0, 0.7)",});
      let send_data = {id: row.tbConfigId};
      send_data[key] = row[key];
        const { data: res } = await this.$http.put(
          `/thirdPartySmallRoutineConfiguration/updateConfiguration`,
          send_data
        );
        loading.close();
        if (res.code != "200") {
          row.status = !row.status;
          return this.$message.error(res.message);
        }
        this.$message.success("操作成功");
      
    },
    // 弹出层，密码框
    async open() {
      let passwordValue; // 用于存储密码输入框的值
      await this.$prompt('请输入密码', '需要密码', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        passwordValue = value; // 用户点击确定时，保存密码值
        this.$message({
          type: 'success',
          message: '已输入密码: ' + value,
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      }).finally(() => {
        this.password = passwordValue; // 使用 finally 确保密码值被设置
      });
    },
    //更新该小程序的组序号
    // row 该小程序的数据
    // newvalue 选择的新组序号
    async change_small_routine_serial(row, value) {
      console.log("row", row)
      console.log("newvalue", value)
      let send_data = {
        id: row.id,
        name: row.name,
        serial: value,
      };
      const { data: res } = await this.$http.post(
          `/thirdPartySmallRoutine/updateSerial`,
          send_data
      );
      if (res.code != "200") {
        row.status = !row.status;
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
      this.getList(); //刷新
    },
    //更改小程序的 属性,这里对应的表应该是小程序表
    // @param row 就是这一行的数据，用来提取id号，一般填写 
    // @param key_name 键值名字，比如说你想要修改小程序的审核状态，就填写submissionStatus，注意表字段名一般驼峰标识
    // 							但是只支持某些字段，有一些字段不支持，这个要看后端的vo有没有支持
    // @param value 更新后的值，比如说上面的审核状态，在开发中就填写 INIT 
    async	change_small_routine_prop(row,key_name,value){
      // 如果是审核状态 和 未备案/已备案的状态下，弹出密码框
      if (key_name==='icpStatus' && (value==='NOT_REGISTER' || value==='REGISTER_SUCCESS')) {
        await this.open();
      }
      const loading = this.$loading({	
          lock: true,	text: "Loading",	spinner: "el-icon-loading",	background: "rgba(0, 0, 0, 0.7)",
      });
      // let send_data = {id: row.id};	//发送的数据 这里是id号
      // send_data[key_name.toString()] = value ;	//这里是发送数据的对应键值的值，如果直接写{key_name,value}不行
      // const result = await this.$http.put(	`/thirdPartySmallRoutine/updateById`,	send_data);
      let send_data = {id: row.id, appletType:this.queryInfo.appletType};	//发送的数据 这里是id号
      send_data[key_name.toString()] = value ;	//这里是发送数据的对应键值的值，如果直接写{key_name,value}不行
      //Yang  新的接口：参数有变
      // const result = await this.$http.put(`/thirdPartySmallRoutine/updateByIdPassword?password=`+ this.password,	send_data);
      const result = await this.$http.put(`/thirdPartySmallRoutine/updateByIdPassword?register=` + this.register + '&password=' + this.password,	send_data);
      // const result = await this.$http.put('/thirdPartySmallRoutine/updateByIdPassword',	send_data);
      loading.close();
      if(result.data.message == true){
        this.$message.success("操作成功");
      }else{
        this.$message.error("操作失败");
      }
      this.getList();	//更新小程序列表
      this.$refs.batch_navigator.initialize_data();	//更新批处理列表，就是更新批处理列表中小程序的数量
    },
    // 打开详细uv数据的弹窗
    show_detail_uv_dialog(small_routine_id){
      this.uv.small_routine_id = small_routine_id ;
      this.uv.is_show_dialog = true;
    },

    //展示关键词编辑弹窗
    show_keyword_dialog(row){
      if(!row.keywordList){
        this.$message.error("请先刷新该小程序的关键词内容");
        return ;
      }
      this.keyword.is_show_dialog = true ;
      this.keyword.keywordList = row.keywordList ;
      this.keyword.appId = row.appId;
      this.keyword.title = row.name ;
    },
    //刷新某个关键词
    async  refresh_keyword(appId){
      let url1 =this.global_url+"tb-third-party-small-routine-keyword/batchquery?appId="+appId
      utility.handleResponse(await axios.get(url1));
      let url2 =this.global_url+"tb-third-party-small-routine-keyword/quotaQuery?appId="+appId
      utility.handleResponse(await axios.get(url2));
      setTimeout(
        async  ()=>{
          await  this.refresh_keyword_table_data();
          this.keyword.keywordList = this.pageMes.list
            .find(e=>e.appId==appId).keywordList;
          this.$message.success("刷新成功");
        } 
        ,200
      );
    },
    //刷新整张表
    async refresh_keyword_table_data(){
      let send_data =  this.pageMes.list.map(e=>e.appId);
      let url = this.global_url+'tb-third-party-small-routine-keyword/listByAppIds';
      const res = await  axios({
        url:url,
        data:{appIdList:send_data},
        method:"POST"
      });
      const data = utility.handleResponse(res);
      const appId_map_keywordInfo = {};
      data.forEach(e=>{
        e.keywordList = JSON.parse(e.keywordList);
        appId_map_keywordInfo[e.appId] = e;
      })
      this.pageMes.list = this.pageMes.list.map(e=>{
        let keywordInfo = appId_map_keywordInfo[e.appId]  ; 
        if(keywordInfo){
          return {...e,...keywordInfo};
        }
        return e;
      })
      return 1;
    },
    //重新加载整个列表的关键词 ,一般一个分页10条记录
    refresh_list_keyword(){
      let appIdList =  this.pageMes.list.map(e=>e.appId) ;
      let delay_milisecond = 500 ;
      appIdList.forEach(appId=>{
        setTimeout( ()=>{this.refresh_keyword(appId)} , delay_milisecond );
        delay_milisecond += 500;
      })
    },
    //Yang 切换 未备案/已备案
    toBan() {
      this.register = this.register === "未备案"? '已备案':'未备案';
      this.getList();	//更新小程序列表
    },
    //Yang 复制组、粘贴组
    async duplicate(){
      const res = await axios({
        url: this.global_url+'thirdPartySmallRoutine/updateDuplicate?usedAppletType='
            + this.queryInfo.appletType + "&usedIcpStatus=" + this.register
      });
      if(res.data.code == "200"){
        this.$message.success("操作成功");
      }else{
        this.$message.error("操作失败");
      }
    },
    async stickup() {
      this.$confirm('确定复制本组，并替换已选的组？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.duplicate(); //复制组
        // 粘贴多个组
        const resse = await axios.post(
            this.global_url + 'thirdPartySmallRoutine/updateStickupssnono',
            this.UpdateStickupssList
        );
        if (resse.data.code == "200") {
          this.$message({
            type: 'success',
            message: '粘贴成功!'
          });
        } else {
          this.$message.error("操作失败");
        }
        this.$refs.batch_navigator.initialize_data();	//（上半部分的分组数据）更新批处理列表，就是更新批处理列表中小程序的数量
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消粘贴'
        });
      });
    },
    //Yang 获取所有第三方数据
    async getAllType() {
      const res = await axios({
        // url: this.global_url+'thirdPartySmallRoutineType/getAllType'
        url: this.global_url+'thirdPartySmallRoutine/selectRoutineType'
      });
      if(res.data.code == "200"){
        this.tbThirdPartySmallRoutineType = res.data.message;
      }else{
        this.$message.error("操作失败");
      }
    },
    //多选，批量粘贴
    change(value) {
      //格式转换
      let convertedList = value.map(items => {
        // items 是一个包含多个元素的数组，例如 ["id1", "name1"]
        return {
          id: items[0],
          name: items[1]
          // 可以继续添加更多的属性
        };
      });
      this.UpdateStickupssList = convertedList;
    },
    //小程序还原回之前的分组
    async serialRestore(){
      const res = await axios.get(
          this.global_url + 'thirdPartySmallRoutine/serialRestore?appletType=' + this.queryInfo.appletType  + "&icpStatus=" + this.register,
      );
      if(res.data.code == "200"){
        this.$message.success("操作成功");
        this.getList() //刷新
      }else{
        this.$message.error("操作失败");
      }
      // console.log("pageMes.list", this.pageMes.list);
    },
    // 应用配置
    async useDeploy() {
      // console.log("组的配置应用给组下的所有小程序")
      // console.log("sort_data_list", this.sort_data_list)
      console.log("选中的值", this.checkedCities);
      this.useDeployLoading = true;
      let batchClassIdList = this.checkedCities.map(city => city.id);
      // console.log("batchClassIdList", batchClassIdList)
      const { data: res } = await this.$http.put(
          "/tb-third-party-wang-batch-class/updateBatchClassAppsMultiConfig?batchClassIdList=" + batchClassIdList
      );
      if(res.code == 200){
        this.$message.success("操作成功");
      }else{
        this.$message.error("操作失败");
      }
      //显示结果
      this.messages = res.message;
      this.useDeployLoading = false
      // console.log("结果", this.messages)
      // 清空选中的内容
      this.checkedCities = [];
    },
    handleCheckAllChange(val) {
      this.checkedCities = val ? this.sort_data_list : [];
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.sort_data_list.length;
    }


  },

  computed: {
    ...mapState({
      global_url:state=>state.global_base_url.main 
    })
  },
};
</script>

<style lang="less" scoped>
.div_home {
  flex: 1;
  .head_input {
    display: flex;
    .div_input {
      display: flex;
      align-items: center;
      // flex: 1;
      // max-width: 350px;
      padding-right: 5px;
      .div_input_text {
        white-space: nowrap;
        padding-right: 5px;
      }
    }
  }
  .head_input_two {
    margin-top: 10px;
  }
  .div_red_color {
    color: red;
  }
  .keyword_button{
    height:40px;
    width:45px;
    //background:#bc8a8a;
    display:flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
  }
}
</style>
