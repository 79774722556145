<!-- 
    该文件是用来解析 跳转对象的 JumpObject ，就是解析一种JSON格式的跳转组件

-->
<template>
<div    class="parse_container">
    <h3>以下是 {{this.content_title}} 的内容</h3>
    <el-form label-width="140px">
        <el-form-item label="标题" v-if="is_show_title">
            <el-input v-model="jump_object.title"></el-input>
        </el-form-item>
        <el-form-item label="背景图片">
            <image_choose	
                :image_url.sync="jump_object.imagePath"
            	:prefix_path="prefix_path"
                :is_show_cdn="true"
            ></image_choose>
        </el-form-item>
        <el-form-item label="图层最上方图片">
            <image_choose	
                :image_url.sync="jump_object.topImagePath"
            	:prefix_path="prefix_path+'/topImage'"
                :is_show_cdn="true"
            ></image_choose>
        </el-form-item>
        <el-form-item label="是否显示">
            <el-switch  v-model="jump_object.isAlive"></el-switch>
        </el-form-item>
        <el-form-item label="生活号显示"  v-if="is_show_lifeId">
            <el-switch  v-model="jump_object.isShowLifeId"></el-switch>
        </el-form-item>
        <el-form-item label="生活号"  v-if="is_show_lifeId">
            <el-input v-model="jump_object.lifeId"></el-input>
        </el-form-item>
        <el-form-item label="跳转类型">
            <el-select v-model="jump_object.jumpType" placeholder="请选择">
                <el-option		label="h5"	value="h5"></el-option>
                <el-option		label="app"	value="app"></el-option>
                <el-option		label="页面"	value="page"></el-option>
                <el-option		label="扫一扫"	value="scan"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="跳转的h5">
            <el-input v-model="jump_object.h5Path"></el-input>
        </el-form-item>
        <el-form-item label="跳转的AppId">
            <el-input v-model="jump_object.appId"></el-input>
        </el-form-item>
        <el-form-item label="跳转的页面">
            <el-input v-model="jump_object.pagePath"></el-input>
        </el-form-item>
    </el-form>    
    <div    class="mix_picture">
        <el-image	
            id="background"
            fit="contain"
            :src="jump_object.imagePath" 
            :lazy="true">
        </el-image>
        <el-image	
            class="top"
            fit="contain"
            :src="jump_object.topImagePath" 
            :lazy="true">
        </el-image>
    </div>
</div>
</template>

<script>
	import axios from 'axios'
    import store from '@/store/index.js'
    import image_choose from './image_choose.vue'
export default{
    components:{
		image_choose
    },
    props: {
        // 背景图片的基本路径
        prefix_path:{
            type:String,
            default:"redpacket/small_mall_1/swiper"
        },
        // 需要解析的内容
        jump_object_json:{
            type:String,
        },
        // 整个组件的大标题
        content_title:{
            type:String
        },
        // 是否展示标题
        is_show_title:{
            type:Boolean,
            default:false,
        },
        // 是否展示生活号
        is_show_lifeId:{
            type:Boolean,
            default:false,
        }
    },
    data() {
        return {
            jump_object:{}
        }
    },
    methods:{
    //下面处理初始化数据的，因为props进来的数据比较慢，需要等props内的参数进来才开始初始化的操作
		//根据输入进来的数据 处理
		async    initiailize_data(){
			//先进行解码（在网络传输中会变内容，所以传输的时候会进行编码解码）
			//后对于JSON格式经行 转化
            if(this.jump_object_json == '{}'){
                this.jump_object = (await axios.get(store.state.global_base_url.main + "jumpObject/getDefault")).data.message;
            }else{
                this.jump_object = JSON.parse(decodeURI(this.jump_object_json)) ;
            }
		},
		//在本地数据还没有初始化之前，把页面内容遮盖，然后轮询props是否初始化完成，再进行本地数据初始化
		wait_util_not_undefined(){
			const  intervalTimer = setInterval(
				()=>{
					if(this.jump_object_json != undefined){
						this.initiailize_data();
						this.is_show_mask = false; 
						clearInterval(intervalTimer);
					}
				},
				100
			)
		}
    },
    computed:{
		
	},
    created(){
		this.wait_util_not_undefined();
    },
	watch:{
		//对结果进行解码，上传给父组件
		jump_object:{
			deep:true,
			handler(newValue){
				this.$emit("update:jump_object_json",encodeURI(JSON.stringify(newValue)));
			}
		}
	}
}
</script>

<style  lang="less" scoped>
.parse_container{
    display: flex;  flex-direction: column;
    padding: 10px;
    border-radius: 10px;
    background-color: #fafafa;
    position: relative;
    .mix_picture{
        position: absolute;
        right: 100px;
        top: 0;
        height: 400px;
        width: 400px;
        .el-image{
            width: 400px;
            height: 400px;
        }
        .top{
            position: absolute;
            top: 0;
            right: 0;
            z-index: 100;
        }
    }
}
</style>